/* AppLayout.css */

/* Global Styles */
.app-layout {
    min-height: 100vh;
    font-family: 'Libre Franklin', sans-serif;
  }
  
  /* Header Styles */
  .app-header {
    position: fixed;
    z-index: 1000;
    width: 100%;
    display: flex;
    align-items: center;
    background: #000000;
    padding: 0 16px;
  }
  
  /* Logo Styles */
  .logo {
    width: 120px;
    height: 64px;
    margin-right: 24px;
  }
  
  .logo-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  /* Menu Styles */
  .custom-menu {
    flex: 1;
    min-width: 0;
    background-color: transparent;
    border-bottom: none;
  }
  
  .menu-item {
    font-weight: 600;
    font-size: 16px;
  }
  
  .menu-link {
    color: #ffffff;
  }
  
  .menu-link:hover {
    color: #f0b71d;
  }
  
  /* Logout Button Styles */
  .logout-button {
    margin-left: auto;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    border-radius: 20px;
    transition: color 0.3s, border-color 0.3s;
  }
  
  .logout-button:hover {
    color: #f0b71d;
    border-color: #f0b71d;
  }
  
  /* Mobile Menu Button */
  .mobile-menu-button {
    margin-left: 16px;
  }
  
  .mobile-menu-icon {
    color: #ffffff;
    font-size: 20px;
  }
  
  .mobile-menu-button:hover .mobile-menu-icon {
    color: #f0b71d;
  }
  
  /* Main Layout Styles */
  .main-layout {
    margin-top: 64px; /* Height of the header */
  }
  
  /* Filters Sider */
  .filters-sider {
    background-color: #f1f5f9;
    position: fixed;
    height: calc(100vh - 64px);
    overflow: auto;
    top: 64px;
    left: 0;
    z-index: 100;
    padding: 16px;
  }
  
  /* Content Layout */
  .content-layout {
    margin-left: 0;
    padding: 24px;
  }
  
  @media (min-width: 992px) {
    .content-layout {
      margin-left: 304px;
    }
  }
  
  /* Content Area */
  .content-area {
    margin: 0;
    min-height: 280px;
    background: #ffffff;
    border-radius: 8px;
    padding: 24px;
  }
  