@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* index.css or App.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* --------------------------------------------------
   Shadcn UI & Global CSS Variables and Base Styles
----------------------------------------------------- */
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
  }

  /* Apply default border color */
  /* * {
    @apply border-border;
  } */

  /* Base styles for body */
  /* body {
    @apply font-sans antialiased bg-background text-foreground;
  } */
}

/* --------------------------------------------------
   Custom Global Classes
----------------------------------------------------- */
/* In your global CSS file */
.blur-background {
  filter: blur(18px); /* Adjust the blur level */
  transition: filter 0.3s ease-in-out;
}

.content {
  padding: 20px;
}

.custom-icon {
  @apply text-gray-600 text-sm;
}

.custom-text {
  @apply text-lg font-semibold text-gray-700 hover:text-blue-500 cursor-pointer;
}

.fade-enter,
.fade-appear {
  opacity: 0;
}

.fade-enter-active,
.fade-appear-active {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Add scaling effect on hover */
.hover\:scale-110:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}


.custom-modal .ant-modal-content {
  background-color: transparent !important; /* Matches your modal background */
  box-shadow: none !important; /* Removes Ant Design's default shadow */
  border-radius: 0 !important; /* Removes any unwanted border radius */
}

.custom-modal .ant-modal-body {
  padding: 0 !important; /* Ensures consistent spacing */
}

.section-header {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-top: 20px;
  border-bottom: 2px solid #e5e5e5;
  padding-bottom: 10px;
}

.bordered-item {
  border: 1px solid #d9d9d9;
  padding: 16px;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s ease-in-out;
}
.bordered-item:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.flex {
  display: flex;
  align-items: center;
}

.items-center {
  align-items: center;
}

.text-pink-500 {
  color: #ff69b4;
}

.text-blue-500 {
  color: #1890ff;
}

.text-yellow-500 {
  color: #faad14;
}

.text-green-500 {
  color: #52c41a;
}

.text-purple-500 {
  color: #722ed1;
}

.text-teal-500 {
  color: #20b2aa;
}

.text-gray-700 {
  color: #595959;
}

.mr-2 {
  margin-right: 8px;
}

.text-lg {
  font-size: 1.25em;
}

.mt-2 {
  margin-top: 8px;
}

.gap-2 {
  gap: 8px;
}

.p-2 {
  padding: 8px;
}

.bg-blue-50 {
  background-color: #e6f7ff;
}

.rounded-lg {
  border-radius: 8px;
}
.hover\:scale-110:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}
.blur-background {
  filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.5);
}

/* CandidateDetails Layout */
.layout {
  max-width: 1200px;
  margin: 0 auto;
}

.card-container {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  background-color: white;
}

/* Button Alignments */
.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.button-group > button {
  margin-left: 8px;
}
